<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { getOpeningHoursRangesAndStatusByDate } from "../opening-hours/utils";
  export let request_store_key: StoresKey;

  const { opening_hours, locale, T } = getStoresContext(request_store_key);
  let today: { ranges: string[]; status: string } | null;
  $: {
    if ($opening_hours && $locale) {
      today = getOpeningHoursRangesAndStatusByDate(
        $opening_hours.shoppingCenterOpeningHours,
        $opening_hours.exceptionalOpeningHours,
        new Date(),
        $locale,
      );
    }
  }
</script>

<div class="ticker">
  <span class="first">
    <span class="highlight">{$T("common.today")}</span>
    {#if today && today.ranges.length}
      {$T(`store.status.${today.status}`)}
      {#each today.ranges as range}
        {#each range.split("–") as o, i}
          <span>{o}</span>
          {#if i == 0}
            <span>— </span>
          {/if}
        {/each}
      {/each}
    {:else}
      {$T(`store.status.CLOSED`)}
    {/if}
  </span>
  <span class="second">
    <span class="highlight">{$T("common.today")}</span>
    {#if today && today.ranges.length}
      {$T(`store.status.${today.status}`)}
      {#each today.ranges as range}
        {#each range.split("–") as o, i}
          <span>{o}</span>
          {#if i == 0}
            <span>— </span>
          {/if}
        {/each}
      {/each}
    {:else}
      {$T(`store.status.CLOSED`)}
    {/if}
  </span>
</div>

<style lang="scss">
  .highlight {
    color: var(--brand-color-5);
    margin-right: 2px;
  }
  .ticker {
    width: 200px;
    overflow: hidden;
    margin-left: 95px;
    position: relative;
    height: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 14px;
    @media screen and (min-width: 992px) {
      margin-left: 150px;
      width: 254px;
      font-size: 20px;
    }
  }
  .ticker .first,
  .ticker .second {
    position: absolute;
    width: 220px;
    color: #fff;
    @media screen and (min-width: 992px) {
      width: 254px;
    }
  }
  .first {
    transform: translateX(0);
    animation: loop1 10s linear infinite;
    -webkit-animation: loop1 10s linear infinite;
  }
  .second {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    animation: loop2 10s linear infinite;
    -webkit-animation: loop2 10s linear infinite;
  }
</style>
