<!-- @format -->
<script lang="ts">
  import { type Alternate } from "~/metadata";
  import { ReverseRouter } from "~/reverse-router";
  import { onMount } from "svelte";
  import OpeningHourTicker from "./OpeningHourTicker.svelte";
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { getPagePurposeValue } from "~/ssr/router/site-router";
  import Logo from "./Logo.svelte";
  import SearchInput from "../common/SearchInput.svelte";

  export let request_store_key: StoresKey;

  const {
    nav_menus,
    site_page,
    reverse_router,
    i18nOf,
    alternate,
    locale,
    site,
  } = getStoresContext(request_store_key);
  $: primary = $nav_menus.find((nav) => nav.navigationType == "Primary");
  $: current = $site_page?.friendlyUrlPath;
  $: is_home_page =
    $site_page && getPagePurposeValue($site_page) == "home page";

  const languages: Record<string, string> = {
    "fi-FI": "FIN",
    "en-US": "ENG",
  };
  $: opposite_lang = $locale == "fi-FI" ? "en-US" : "fi-FI";

  let body: HTMLBodyElement;
  let isOpen = false;

  onMount(() => {
    body = document.getElementsByTagName("body")[0];
  });
  function hrefFor(
    locale: string,
    alternate: readonly Alternate[],
    reverse_router: Readonly<ReverseRouter>,
  ) {
    const alt = alternate.find((x) => x.locale == locale);
    return alt
      ? alt.href
      : reverse_router.findPurposeRoute("home page", locale);
  }
  $: home_page_nav_item = $reverse_router.routePurpose("home page");
  $: {
    if (isOpen && body) {
      body.classList.add("overflow-hidden");
    } else if (!isOpen && body) {
      body.classList.remove("overflow-hidden");
    }
  }
</script>

<header class="fixed-top" id="header">
  <nav>
    <a href={home_page_nav_item}>
      <Logo logo={$site.logo} {is_home_page} />
    </a>
    <OpeningHourTicker {request_store_key} />
    <div class="right">
      <div class="d-lg-block d-none">
        <SearchInput {request_store_key} />
      </div>
      <a
        class="langs d-lg-flex d-none"
        title={languages[opposite_lang]}
        href={hrefFor(opposite_lang, $alternate, $reverse_router)}
        >{languages[opposite_lang]}</a>
      <button
        class="button-icon"
        on:click={() => {
          isOpen = true;
        }}>
          <img src="/theme/kamppi2024/images/burger.png">
        </button>
    </div>
  </nav>
  {#if primary}
    <div class="fade" class:open={isOpen}></div>
    <div class="dropdown d-flex" class:open={isOpen}>
      <div
        class="col-lg-6 backdrop d-none d-lg-block"
        on:click={() => {
          isOpen = !isOpen;
        }}>
      </div>
      <div class="dropdown-container col-lg-6 list">
        <div>
          <div class="d-lg-none d-block">
            <SearchInput {request_store_key} />
          </div>
          {#each primary.navigationMenuItems as item}
            <a
              class="dropdown-link"
              href={item.link ? $reverse_router.routePage(item.link) : item.url}
              class:active={item.link == current}
              target={item.link ? "_self" : "_blank"}>{i18nOf(item.name_i18n)}</a>
          {/each}
          <a
            class="langs d-lg-none d-flex"
            title={languages[opposite_lang]}
            href={hrefFor(opposite_lang, $alternate, $reverse_router)}
            >{languages[opposite_lang]}</a>
        </div>
        <button
        class="button-icon crossed"
        on:click={() => {
          isOpen = false;
        }}>
        <img src="/theme/kamppi2024/images/close.png">
      </button>
      </div>
    </div>
  {/if}
</header>

<div class="gap"></div>

<style lang="scss">
  .dropdown-container {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 993px) {
      flex-direction: column;
    }
  }
  .fade {
    width: 100%;
    position: fixed;
    height: 100vh;
    opacity: 0.5;
    cursor: pointer;
    display: none;
    animation: fadeBackground 1s;
    animation-fill-mode: forwards;
  }
  @keyframes fadeBackground {
    from { background-color: transparent; }
    to { background-color: var(--headerColor); }
  }
  .dropdown {
    width: 100%;
    transition: all 0.7s ease;
    top: 0;
    position: fixed;
    color: #fff;
    height: 100vh;
    left: 100%;
    @media screen and (min-width: 992px) {
      max-height: 100vh;
      flex-direction: row;
    }
    .list {
      padding: 100px 70px;
      padding-bottom: 180px;
      text-align: center;
      background-color: var(--headerColor);
      overflow: auto;
      position: relative;
      @media screen and (min-width: 992px) {
        padding: 40px 106px 0 50px;
        text-align: left;
      }
    }
    .backdrop {
      cursor: pointer;
    }
    a {
      display: block;
      margin-bottom: 19px;
      font-size: 20px;
      color: #fff;
      @media screen and (min-width: 992px) {
        margin-bottom: 25px;
        font-size: 30px;
      }
    }
    a.active {
      color: var(--brand-color-5);
      text-decoration: underline;
    }
    a:hover {
      color: var(--brand-color-5);
      text-decoration: underline;
    }
  }
  .fade.open {
    display: block;
  }
  .dropdown.open {
    left: 0;
  }
  .button-icon {
    display: flex;
    height: 42px;
    justify-content: center;
    width: 42px;
    align-items: center;
    color: #fff;
    transition: all 0.3s;
    position: relative;
    background: none;
    border: 0;
    padding: 0;
    img{
      width: 32px;
      @media screen and (min-width: 992px) {
        width: 53px;

      }
    }
  }
  .button-icon:hover {
    transform: scale(1.06);
  }

  a.langs {
    display: flex;
    align-items: center;
    border: 2px solid #fff;
    width: 120px;
    justify-content: center;
    border-radius: 50px;
    color: #fff;
    margin: auto;
    margin-top: 50px;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @media screen and (min-width: 992px) {
      margin-right: 64px;
      width: 76px;
      margin-top: 0;
      height: 100%;
      font-size: 20px;
      padding-top: unset;
      padding-bottom: unset;
    }
  }
  .langs:hover {
    background-color: #fff;
    color: var(--headerColor) !important;
    text-decoration: none !important;
  }
  .right {
    height: 100%;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .gap {
    height: 70px;
    @media only screen and (min-width: 992px) {
      height: 105px;
    }
  }

  nav {
    padding: 21px 20px;
    background-color: var(--headerColor);
    height: 70px;
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: 992px) {
      padding: 30px 60px;
      height: 105px;
    }
  }
  .dropdown-link {
    font-family: var(--font-items);
  }
  .list .button-icon {
    position: absolute;
    top: 37px;
    right: 60px;
    img{
      width: 23px;
      @media screen and (min-width: 992px) {
        width: 40px;
      }
    }
  }

</style>
