
import { SimpleRoute, SimpleRouter } from '@marianmeres/simple-router';
import type { WritableAtom } from 'nanostores';
import type { SiteDefinition } from '~/configuration/types';
import { 
  type PagePurpose,
  type PageParams,
  type RouterReply,
  page_purpose_index,
  type PageReply } from './page-purpose';
import { ReverseRouter, type ReverseRoute } from '~/reverse-router';
import { StoresContext } from '~/stores';

type SanityCheck = Map<PagePurpose, SitePageSpec>;

type ReplyFn = (params: PageParams & Record<string, string>) => RouterReply;

function replyPage (locale: string, page: SitePageSpec, friendly_url_path_api: string) {
  return function PageReply(params: PageParams) {
    return {
      locale,
      page,
      friendly_url_path_api,
      params: ReverseRouter.decodeParams(params),
    }
  };
}
function replyRedirect (redirect: string, permanent = true) {
  return function Redirect () {
    return {
      redirect,
      permanent,
    }
  };
}

export function getExcludedLocales (page: WithCustomFields): string[] {
  const field = getCustomField('Excluded Locales', page) as TextCustomField;
  if (!field || !field?.customValue?.data) return [];
  return field.customValue.data.trim().split(/\s*,\s*/g);
}

export function getFriendlyPathPatternValue (page: SitePageSpec | SitePage | ThinPage, locale: string): string {
  const path_pattern = getCustomField('Friendly Path Pattern', page) as TextCustomField;
  if (!path_pattern) return '';
  const { data_i18n = {} } = path_pattern.customValue;
  return data_i18n[locale] || path_pattern.customValue.data || '';
}

export function getPagePurposeValue (page: SitePageSpec | SitePage | ThinPage): PagePurpose {
  const field = getCustomField('Page Purpose', page) as TextSelectCustomField;
  if (!field) return 'normal';
  return field.customValue.data.length != 1 ? 'normal' : field.customValue.data[0] as PagePurpose;
}

export function getCustomField (
  name: 'Page Purpose' | 'Friendly Path Pattern' | 'Excluded Locales', 
  page: WithCustomFields
): unknown | undefined {
  try {
    const x = page.customFields.find(x => x.name == name);
    return x
  } catch (error) {
    console.log('getPageCustomField', page);
    console.error(error);
  }
}

function getPagePurpose(page: SitePageSpec, sanity_check: SanityCheck): PagePurpose {
  const purpose = getPagePurposeValue(page);

  if (purpose == 'normal' || purpose == '---') return 'normal';

  if (sanity_check.has(purpose)) {
    console.error(
      `Same PagePurpose '${purpose}' more than once! Demoting the latest`,
      [sanity_check.get(purpose)?.friendlyUrlPath, page.friendlyUrlPath]
    );
    return 'normal';
  }

  sanity_check.set(purpose, page);
  return purpose;
}

type LocalePathTuple = [locale: string, path: string];
type DefaultAnotherTuples = [
  default_locale: LocalePathTuple[],
  other_locales: LocalePathTuple[],
];

function translatedPaths(page: SitePageSpec, scope: string): [string, string][] {
  return Object.entries(page.friendlyUrlPath_i18n)
    .filter(([_, path]) => !path.startsWith('/_'))
    .map(e => (e[1] = scope + e[1], e));
}


const SIMPLE_ROUTER_PATTERN_PREFIX = '^=>';

export class SiteRouter extends SimpleRouter {

  private label: string;
  readonly patterns: Map<PagePurpose, string>;
  readonly page_purpose_pages: Map<PagePurpose, Pick<PageReply, 'page' | 'friendly_url_path_api'>>;

  constructor(site_def: SiteDefinition, stores_context: StoresContext) {
    super();

    this.label = `${site_def.name} routes`;
    console.time(this.label);

    this.patterns = new Map();
    this.page_purpose_pages = new Map();

    
    const { site_details, site_pages } = stores_context;
    const { sitePageSpecs, redirects } = stores_context.site_details.get();

    const site_page_map = new Map(
      site_pages.get().map(x => [x.uuid, x])
    )
    
    // BUG in API: SitePageSpecs are missing customFields
    sitePageSpecs.forEach(x => {
      const page = site_page_map.get(x.uuid);
      if (page) {
        x.customFields = page.customFields;
        x.title = page.title;
        x.title_i18n = page.title_i18n;
      }
    })

    this.addRedirects(site_def, site_details.get());  
    this.addPageRoutes(site_def, sitePageSpecs);

    console.timeEnd(this.label);
  }

  private addPageRoutes(site_def: SiteDefinition, site_pages: SitePageSpec[]) {

    const { 
      default_locale, 
      scopePath: scope = '',
      supported_languages = [],
    } = site_def;

    // There should be only one of each other than normal purpose
    const sanity_check: SanityCheck = new Map<PagePurpose, SitePageSpec>();

    site_pages.forEach(page => {

      // We currently handle only content pages
      if (!['content', 'Content Page'].includes(page.page_type)) {
        console.warn('Skipping non-content page', page.title, page.friendlyUrlPath, page.page_type);
        return;
      }

      // const path_pattern = getPageCustomField('Friendly Path Pattern', page) as TextCustomField;
      const page_purpose = getPagePurpose(page, sanity_check);
      const api_friendly_url_path = page.friendlyUrlPath_i18n['en-US'];
      const excluded_locales = getExcludedLocales(page);
      
      const translated_paths = translatedPaths(page, scope);

      if (page_purpose != 'normal') {
        this.page_purpose_pages.set(page_purpose, { 
          page, 
          friendly_url_path_api: api_friendly_url_path,
        });
      }

      for (let [locale, path] of translated_paths) {
        const skip_excluded = excluded_locales.some(x => x == locale)
        const is_active_locale = locale == default_locale || supported_languages.some(x => x == locale);

        if (skip_excluded || !is_active_locale) { continue; }

        const [lang] = locale.split(/[_-]/);
        const is_default = locale == default_locale;
        const is_home = page_purpose == 'home page';

        // Home page
        if (is_home) {
          const lang_seg = is_default ? '' : `/${lang}`;
          const home_path = SiteRouter.join(lang_seg, scope, '/')
          const redirect_path = SiteRouter.join(lang_seg, path); // Path already includes scope

          this.add(home_path, replyPage(locale, page, api_friendly_url_path), page_purpose);
          this.add(redirect_path, replyRedirect(home_path), page_purpose);
          continue;
        }

        const path_pattern = getFriendlyPathPatternValue(page, locale);
        const is_detail_page = 
          (page_purpose.endsWith(' detail') || page_purpose.startsWith('custom')) &&
          !!path_pattern;

        if (is_detail_page) {
          // const { data_i18n = {} } = path_pattern.customValue
          // path = data_i18n[locale] || path_pattern.customValue.data;
          path = path_pattern;
          this.patterns.set(page_purpose, path);
        }

        // Normal
        const public_path = is_default
          ? SiteRouter.join(`/${path}`)
          : SiteRouter.join(`/${lang}`, path)

        this.add(
          public_path,
          replyPage(locale, page, api_friendly_url_path),
          page_purpose
        )
      }
    })
  }


  private addRedirects(site_def: SiteDefinition, site_details: SiteDetails) {

    for (const redirect_pattern of site_details.redirectPatterns) {
      if (!redirect_pattern.pattern.startsWith(SIMPLE_ROUTER_PATTERN_PREFIX)) continue;

      const pattern = redirect_pattern.pattern
        .replace(SIMPLE_ROUTER_PATTERN_PREFIX, '')
        .replace(/\\\\/, '\\');
      
      this.add(
        pattern,
        function PatternRedirect (params: PageParams) {
          if (params && params.lang) {
            params.locale = site_def.supported_languages?.find(x => x.startsWith(params.lang as string)) || site_def.default_locale;
          }
          return {
            redirect: redirect_pattern.destination_url,
            permanent: true,
            params
          }
        },
        'normal',
        true
      )
    }

    const site_origin = new RegExp(`^https?://${site_def.domain}`);
    const now = new Date().toISOString();

    for (const redirect of site_details.redirects) {

      const { destination_url, dateExpiration, source_url, permanent } = redirect;
      const redirect_url = destination_url.replace(site_origin, '');
      
      if (dateExpiration && dateExpiration < now) continue;

      this.add(
        source_url.startsWith('/') ? source_url : `/${source_url}`,
        function Redirect (params: PageParams) {
          return {
            redirect: redirect_url,
            permanent: permanent,
            params
          }
        },
        'normal',
        true
      )
    }
  }

  add(routes: string | string[], cb: ReplyFn, page_purpose: PagePurpose, allowQueryParams = true) {
    if (!Array.isArray(routes)) {
      routes = [routes];
    }

    routes.forEach((route) => {
      if (route === '*') {
        this._catchAll = cb;
      }
      else {
        this._routes.push([new SimpleRoute(route), cb, allowQueryParams, page_purpose]);
      }
    });
  }

  exec(url: string, fallbackFn?: Function | undefined): RouterReply | false {
    return super.exec(url, fallbackFn);
  }

  toReverseRouter(locale: WritableAtom<string>) {
    return new ReverseRouter(
      this._routes.reduce((acc: ReverseRoute[], [route, cb, , purpose]) => {
        const reply: RouterReply = cb();

        if ('redirect' in reply) return acc;

        acc.push([
          page_purpose_index.indexOf(purpose),
          reply.locale,
          reply.friendly_url_path_api,
          route.route
        ]);

        return acc
      }, []),
      locale
    )
  }

  printRoutes () {
    console.log(this.label);
    this._routes
      .slice(0)
      .sort(([a], [b]) => a.route < b.route ? -1 : 1)
      .forEach(([route, cb, _, purpose]) => console.log(route.route, '=>', cb, purpose))
  }

  static join(...path_segments: string[]) {
    return path_segments.join('/').replace(/[/]+/g, '/');
  }

}

