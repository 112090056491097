<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";

  export let is_home_page = false;
  export let logo 
  $: scrollY = 0;
  $: height = 90;
  $: top = 150;
  $: banner_height = 1;
  $: clientWidth = 0;
  $: {
    if (is_home_page) {
      if (clientWidth > 1440) {
        height =
          (scrollY * 45) / banner_height < 45
            ? 90 - (scrollY * 45) / banner_height
            : 45;
        top =
          (scrollY * 120) / banner_height < 120
            ? 150 - (scrollY * 120) / banner_height
            : 30;
      }
      else if (clientWidth > 992) {
        height =
          (scrollY * 45) / banner_height < 45
            ? 65 - (scrollY * 45) / banner_height
            : 45;
        top =
          (scrollY * 120) / banner_height < 120
            ? 150 - (scrollY * 120) / banner_height
            : 30;
      }
      else {
        height = 27;
        top =
          (scrollY * 78.5) / banner_height < 78.5
            ? 100 - (scrollY * 78.5) / banner_height
            : 21.5;
      }
    }
  }
  onMount(() => {
    const main_banner: HTMLElement | null =
      document.getElementById("main-banner");
    if (main_banner) banner_height = main_banner.clientHeight / 2 || 1;
  });
</script>

<svelte:window bind:scrollY bind:innerWidth={clientWidth} />
<img
  src={logo}
  alt="Kamppi Helsinki"
  class="logo"
  class:stick={!is_home_page}
  style="--height: {height}px; --top: {top}px" />

<style lang="scss">
  .logo {
    position: absolute;
    height: var(--height);
    top: var(--top);
    transition: all 0.1s ease;
  }
  .stick {
    height: 27px;
    top: 21.5px;
    @media screen and (min-width: 992px) {
      height: 45px;
      top: 30px;
    }
  }
</style>
